.acknowledgements {
	// background-image: url('../images/vooruitblijkendank.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 120px;

  .card {
    z-index: 1;
  }

  .board-members {
    border-bottom-right-radius: 20px;
	border-top-right-radius: 20px;
    height: auto;
    left: -50px;
    max-width: none;
    position: absolute;
    right: 0;
    top: 55px;
    width: calc(100% + 100px);
    z-index: 0;
  }

  @include media-breakpoint-down(md) {
    margin-top: 30px;

    .board-members {
      border-radius: 20px;
	  margin-top: -80px;
	  position: relative;
    }
  }
}

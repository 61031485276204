.usps {
  margin-top: 200px;

  &__usps {
    margin-top: 75px;
  }

  @include media-breakpoint-down(md) {
    margin-top: 360px;

    &__usps {
      margin-top: 10px;

      .usp {
        margin-bottom: 15px;
      }
    }
  }
}

.section-intro {
  display: inline-flex;
  margin-bottom: 25px;

  &__title {
    // margin: auto auto auto 28px;
    font-size: 18px;
    letter-spacing: 3px;
    color: $purple;
    font-weight: 600;
    text-transform: uppercase;
    font-family: $font-museo-sans;
  }

  .icon {
    height: 72px;
    width: 72px;
    background-color: rgba($purple, 0.1);
    display: flex;
    border-radius: 20px;

    svg {
      margin: auto;
      width: 42px;
    }
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 20px;

    &__title {
      // margin: auto auto auto 20px;
      font-size: 12px;
      line-height: 40px;
      letter-spacing: 2px;
    }

    .icon {
      width: 40px;
      height: 40px;
      border-radius: 10px;

      svg {
        width: 23px;
      }
    }
  }
}

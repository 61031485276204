.content-modal {
  opacity: 0;
  // z-index: -100;
  z-index: 99999;
  pointer-events: none;
  position: fixed;
  top: 0;
  overflow-y: scroll;
  scroll-behavior: smooth;
  background: rgba(#2c0334, 0.8);
  height: 100vh;
  width: 100vw;
  padding-top: 25vh;
  transition: opacity 500ms ease;

  &::after {
    content: '';
    display: block;
    padding-bottom: 25vh;
  }

  .section-intro {
    .icon {
      background-color: $white;
    }
  }

  p,
  h1,
  h2,
  h3,
  a {
    color: $white;
  }

  a {
    text-decoration: underline;
  }

  &__close {
    height: 49px;
    width: 48px;
    border-radius: 100%;
    background-color: #ff4e6b;
    position: absolute;
    right: 25px;
    top: 15px;
    display: flex;
    cursor: pointer;

    &.bottom {
      top: auto;
      bottom: -65px;
    }

    &::after,
    &::before {
      content: '';
      width: 20px;
      height: 2px;
      background-color: $white;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 1px;
      margin: auto;
    }

    &::after {
      transform: rotate(45deg);
    }

    &::before {
      transform: rotate(-45deg);
    }
  }

  div[data-content] {
    display: none;
  }

  @include media-breakpoint-down(md) {
    padding-top: 10vh;
    padding-bottom: 10vh;

    &::after {
      padding-bottom: 10vh;
    }

    &__close {
      &.bottom {
        bottom: -40px;
      }
    }
  }
}

body.modal-open {
  overflow: hidden;

  main.wrapper > * {
    filter: blur(20px);
  }

  .content-modal {
    opacity: 1;
    pointer-events: auto;
  }
}

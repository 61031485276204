.testimonial {
  margin-top: 100px;
  height: 640px;

  &__image {
    position: absolute;
    // background-image: url('../images/foto02.jpg');
    width: calc(100vw - 95px);
    height: 640px;
    background-position: 0 80%;
    background-size: cover;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
    right: 0;
  }

  .card {
    background: radial-gradient(circle at 120% -100%, $pink 0%, $purple 70%);
    padding-top: 10px;

    .quote {
      position: absolute;
      margin-top: -40px;
      margin-left: -20px;
      width: 155px;
    }

    .card-body {
      padding-bottom: 40px;
    }

    blockquote {
      color: $white;

      footer {
        margin-top: 25px;
        opacity: 0.5;

        &::before {
          content: '';
          display: inline-block;
          margin: 5px 20px 5px 0;
          width: 50px;
          height: 1px;
          background: $white;
        }
      }
    }

    &::after {
      content: '';
      width: calc(100% - 60px);
      height: 15px;
      margin-bottom: -15px;
      margin-left: 30px;
      background-color: #a779ac;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }

  &.v1 {
    margin-top: 210px;

    .card {
      margin-top: -90px;
    }
  }

  &.v2 {
    margin-bottom: 95px;

    .testimonial__image {
      background-position: center;
    }

    .card {
      margin-top: 420px;
    }
  }

  @include media-breakpoint-down(md) {
    height: 500px;

    &__image {
      width: 100%;
      border-radius: 0;
      height: 500px;
      background-position: left center;
    }

    .card {
      .quote {
        margin: -10px;
        width: 77px;
      }

      blockquote {
        margin: 20px 10px 0 10px;

        footer {
          &::before {
            width: 25px;
            margin: 4px 10px 4px 0;
          }
        }
      }
    }

    &.v1 {
      margin-top: 265px;
    }

    &.v2 {
      height: 100%;
      margin-top: 35px;
      margin-bottom: 15px;

      .card {
        margin-top: 350px;
      }

      .testimonial__image {
        height: 500px;
        background-position: center;
        background-size: 100%;
      }

      @include media-breakpoint-down(xs) {
        .testimonial__image {
          height: 500px;
          background-position: 20% 100%;
          background-size: 150%;
        }
      }
    }
  }
}

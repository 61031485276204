.board-report {
  margin-top: 90px;
  position: relative;

  .card {
    margin-top: 460px;
  }

  &__image {
    position: absolute;
    background-image: url('../images/bestuursverslag.jpg');
    width: calc(100vw - 95px);
    height: 50vh;
    min-height: 560px;
    background-position: top center;
    background-size: cover;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
    right: 0;

    // .image-gradient {
    //   background-size: cover;
    //   position: absolute;
    //   right: 0;
    //   width: 50%;
    //   height: 100%;

    //   background-image: url('../images/gradient-1.png');
    // }
  }

  body.stick-menu &,
  body.modal-open & {
    padding-top: 64px;
  }

  @include media-breakpoint-down(lg) {
    body.stick-menu &,
    body.modal-open & {
      padding-top: 61px;
    }
  }

  @include media-breakpoint-down(md) {
    margin-top: -50px;
	
    .card {
      margin-top: 205px;
    }

    &__image {
      min-height: 0;
      height: 400px;
      width: 100%;
      border-radius: 0;
    }
  }
}

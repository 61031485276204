.finance {
  margin-top: 140px;
  margin-bottom: 70px;
  padding: 70px 0;
  background-image: url('../images/gradient-6.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  &__image {
    position: absolute;
    background-image: url('../images/Financien.jpg');
    background-size: cover;
    background-position: center;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 45%;
    height: 100%;
    margin-bottom: -70px;
  }

  p,
  h1,
  h2 {
    color: $white;
  }

  .section-intro {
    .icon {
      background-color: $light-green;

      svg path {
        fill: $green;
      }
    }
  }

  .btn-plus {
    background-color: $white;
    animation: pulse-white 2s infinite;

    &::before,
    &::after {
      background-color: $purple;
    }
  }

  @include media-breakpoint-down(md) {
    margin-top: 60px;
    padding-top: 40px;
    padding-bottom: 240px;
    margin-bottom: 310px;

    &__image {
      height: 500px;
      width: calc(100vw - 30px);
      margin-left: 15px;
      border-radius: 10px;
      bottom: -240px;
    }
  }
}

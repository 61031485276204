.our-team {
  margin-top: 90px;
  position: relative;

  .card {
    margin-top: 360px;
  }

  &__image {
    position: absolute;
    background-image: url('../images/Onsteam.jpg');
    width: calc(100vw - 95px);
    height: 50vh;
    min-height: 560px;
	background-position: center;
    background-size: cover;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
    right: 0;

    // .image-gradient {
    //   background-size: cover;
    //   position: absolute;
    //   right: 0;
    //   width: 50%;
    //   height: 100%;

    //   background-image: url('../images/gradient-1.png');
    // }
  }

  @include media-breakpoint-down(md) {
    margin-top: 0;

    .card {
      margin-top: 205px;
    }

    &__image {
      height: 250px;
      width: 100%;
      border-radius: 0;
    }
  }
}

.hero {
  &__top {
    position: relative;
    height: 100vh;
    background-image: url('../images/Headerafbeelding.jpg');
    background-size: cover;
    background-position: center;

    .top-banner {
      display: flex;
    }

    .brand {
      margin: 50px auto auto 50px;

      .logo {
        display: block;
        width: 135px;
        margin-bottom: 5px;
      }

      .we-cash {
        display: block;
        width: 114px;
        margin-bottom: 10px;
      }

      .we-care {
        display: block;
        width: 107px;
      }
    }

    .links {
      margin: 50px 50px auto auto;

      .btn.btn-external {
        color: rgb(255,255, 255);
        border: 2px solid rgb(255, 255, 255);
        border-radius: 50px;
        padding: 2px 0 0 19px;

        svg {
          margin-top: -2px;
          width: 40px;
          height: 40px;
        }
      }
    }

    .content {
      display: flex;

      .back {
        color: rgba($white, 0.2);
        position: absolute;
        z-index: 0;
        font-size: 331px;
        width: 100%;
        display: flex;
        top: 30vh;
        left: 0;
        text-align: center;

        span {
          width: 100%;
        }
      }

      .front {
        width: 100%;
        position: absolute;
        top: 30vh;
        left: 0;
        margin-top: -57px;
        // animation: fadeInTop;
        // --animate-duration: 0.2s;
        // --animate-delay: 1s;

        h1 {
          color: $white;
          text-align: center;
          font-size: 80px;
          line-height: 79px;
        }
      }

      .icons {
        position: absolute;
        width: 100%;
        left: 0;
        top: 50vh;

        ul {
          max-width: 825px;
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;
          margin: auto;

          li {
            margin: auto;
            display: flex;
            position: relative;

            &::before {
              content: '';
              position: absolute;
              z-index: 0;
              width: 136%;
              height: 136%;
              top: -18%;
              left: -18%;
              border-radius: inherit;
            }

            svg {
              z-index: 1;
              width: 100%;
              height: 60px;

              path {
                fill: $white;
              }
            }

            &:first-of-type {
              svg {
                height: 35px;
              }
            }
          }
        }
      }

      .text {
        width: 100%;
        position: absolute;
        top: 50vh;
        left: 0;
        text-align: center;
        color: $white;
        font-weight: 700;

        p {
          margin: 1rem auto 0;
          max-width: 740px;
          font-size: 22px;
          line-height: 40px;
        }
      }
    }

    .scroll-down {
      position: absolute;
      bottom: 70px;
      width: 100%;
      text-align: center;

      svg {
        width: 28px;
      }
    }
  }

  &__main {
    position: relative;
    margin: 94px 0 0;
    padding-bottom: 77px;
    height: 250px;
    transition: height 500ms ease;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      height: auto !important;
    }

    .text-wrapper {
      display: flex;

      p:first-of-type {
        margin-right: 10px;
      }

      p:last-of-type {
        margin-left: 10px;
      }

      p {
        min-width: 50%;
      }
    }

    .read-more {
      display: none;
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 120px;
      background: linear-gradient(rgba($white, 0.5) 0%, rgba($white, 1) 35%);

      .line {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        bottom: 40px;
        border-bottom: 1px solid $borderGray;
      }

      button {
        position: absolute;
        bottom: 20px;
        overflow: visible;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    &.text-open {
      .read-more {
        background: linear-gradient(rgba($white, 0) 0%, rgba($white, 1) 35%);

        svg {
          transform: rotate(180deg);
        }
      }
    }

    .slogan {
      display: block;
      margin-top: 25px;
      font-weight: 700;
      color: $dark;
    }
  }

  @include media-breakpoint-down(md) {
    .content {
      .back {
        font-size: 231px;
      }

      .front {
        margin-top: -55px;

        h1 {
          font-size: 80px;
          line-height: 79px;
        }
      }
    }

    &__main {
      margin: 20px 0 0a;

      .container {
        margin-bottom: 80px;
      }

      .text-wrapper {
        flex-wrap: wrap;

        p {
          width: 100%;
          margin: 0 0 25px 0 !important;
        }
      }

      .read-more {
        margin-top: 90px;
        display: block;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    &__top {
      .top-banner {
        .we-cash,
        .we-care {
          display: none;
        }
      }

      .brand {
        margin: 50px auto auto 15px;
      }

      .links {
        margin: 35px 5px auto auto;
      }

      .content {
        .back {
          font-size: 40vmin;
        }

        .front {
          margin-top: -13px;

          h1 {
            font-size: 40px;
            line-height: 39px;
          }
        }

        .icons {
          top: 50vh;
          width: calc(100% - 40px);
          margin: auto 20px;

          ul {
            li {
              svg {
                height: 26px;
              }

              &:first-of-type {
                svg {
                  height: 15px;
                }
              }
            }
          }
        }

        .text {
          top: 50vh;

          padding: 0 15px;
          p {
            font-size: 20px;
            line-height: 28px;
          }
        }
      }
    }
  }
}

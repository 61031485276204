.numbers {
  margin-top: 100px;
	background-color: #F4F4F6;
  //background-image: url('../images/gradient-7.png');
  //background-size: cover;
  //background-position: center;
  padding-top: 70px;
  padding-bottom: 170px;

  &__progress-bars {
    h3 {
      font-size: 18px;
      color: $dark;
      font-weight: normal;
      margin-top: 50px;
    }

    .year {
      color: #485465;
      width: 50px;
      height: 100%;
      font-size: 14px;
      font-weight: 300;
      margin: auto 0;
    }

    .item {
      display: flex;
      margin: 15px 0 18px;
    }

    .progress-wrapper {
      position: relative;
      width: 100%;
      margin: auto;
    }

    .progress {
      background: rgba($gray, 0.2);
      width: 100%;
      border-radius: 20px;
      height: 10px;
      margin: auto;
      overflow: hidden;

      .progress-bar {
        border-radius: 20px;
        height: 100%;
        transform: translateX(-100%);
        transition: transform 1000ms ease;
      }
    }

    label {
      position: absolute;
      background: white;
      font-size: 14px;
      padding: 6px 10px 5px 10px;
      color: $dark;
      font-weight: normal;
      line-height: 14px;
      top: 18px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
      opacity: 0;
      transition: opacity 1000ms ease 1200ms;

      &::before {
        content: '';
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $white;
        position: absolute;
        left: calc(50% - 2.5px);
        top: -5px;
      }
    }

    .first-progress-bar {
      .progress-bar {
        background-color: $purple;
      }
    }

    .second-progress-bar {
      .progress-bar {
        background-color: $green;
      }
    }

    .item.in-view {
      label {
        opacity: 1;
      }

      .progress {
        .progress-bar {
          transform: translateX(0%);
        }
      }
    }
  }

  &__cards {
    margin-top: 100px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 40px 20px;

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media-breakpoint-down(sm) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    h3 {
      font-size: 16px;
      color: $dark;
      font-weight: 700;
      margin-top: 20px;
      margin-bottom: 0;
    }
    span {
      font-size: 12px;
      color: $dark;
      font-weight: 300;
      margin-top: 50px;
      margin-top: -8px;
    }

    .card {
      padding: 25px 50px;
      text-align: center;
      display: flex;

      svg {
        width: 32px;
        margin: auto;
      }

      canvas {
        height: 200px;
        margin-top: 20px;
      }

      h2 {
        color: $purple;
      }
    }
  }

  @include media-breakpoint-down(md) {
    margin-top: 60px;
    padding-top: 30px;
    padding-bottom: 30px;

    &__progress-bars {
      .item {
        margin: 20px 0 25px;
      }
    }
  }
}

.menu {
  border-top: 1px solid $borderGray;
  border-bottom: 1px solid $borderGray;
  display: flex;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 999;
  position: relative;

  &__brand {
    border-right: 1px solid $borderGray;
    padding: 20px 45px 10px 50px;
    box-shadow: 2px 0 5px -4px $dark;

    svg {
      height: 30px;
    }
  }

  &__nav {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    position: relative;
    padding-left: 20px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }

    ul {
      padding: 0;
      margin: auto;
      position: absolute;
      width: 2100px;

      // @media (min-width: 1400px) {
      //   right: 0;
      // }

      li {
        display: inline-block;
        padding: 0 20px;

        a {
          font-size: 16px;
          display: block;
          color: $gray;
          padding: 14px 0;
          border-bottom: 3px solid transparent;
        }

        &:hover {
          a {
            text-decoration: none;
            color: $dark;
          }
        }

        &.current-menu-item a {
          text-decoration: none;
          color: $dark;
          font-weight: 600;
          border-color: $purple;
        }
      }
    }
  }

  &__right {
    width: 60px;
    background-color: $white;
    border-left: 1px solid $borderGray;
    box-shadow: -2px 0 5px -4px $dark;
    flex: none;
    display: flex;
    cursor: pointer;

    svg {
      margin: auto;
      width: 26px;
      height: 26px;
    }
  }

  &__mobile-trigger {
    display: none;
    margin: auto 15px auto auto;
    cursor: pointer;

    .bars {
      height: 26px;
      width: 26px;
      border-radius: 100%;
      background-color: $dark;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .bar {
        margin: 1px auto;
        height: 2px;
        border-radius: 2px;
        margin-bottom: 2px;
        background-color: $white;
        transition: all 350ms ease;
      }

      .bar:nth-child(1) {
        width: 12px;
      }

      .bar:nth-child(2) {
        width: 16px;
      }

      .bar:nth-child(3) {
        width: 12px;
      }

      body.menu-open & {
        .bar:nth-child(1) {
          transform: rotate(45deg);
          width: 16px;
        }

        .bar:nth-child(2) {
          opacity: 0;
        }

        .bar:nth-child(3) {
          transform: rotate(-45deg);
          margin-top: -9px;
          width: 16px;
        }
      }
    }
  }

  body.stick-menu &,
  body.modal-open & {
    position: fixed;
  }

  @include media-breakpoint-down(md) {
    border-top: none;

    &__brand {
      box-shadow: none;
      border: none;
      padding-left: 15px;
    }

    &__nav {
      padding: 0;
      display: block;
      position: fixed;
      top: 61px;
      right: 0;
      width: 100vw;
      height: 100vh;
      background: white;
      z-index: 9999;
      overflow: scroll;
      transform: translateX(100vw);
      transition: transform 300ms ease;

      ul {
        width: 100%;

        li {
          width: 100%;
          padding-left: 0;
          border-bottom: 1px solid rgba(#41434f, 20%);

          a {
            padding-left: 20px;
            border-bottom: 0;
            border-left: 8px solid transparent;
          }
        }
      }

      body.menu-open & {
        transform: translateX(0);
      }
    }

    &__right {
      display: none;
    }

    &__mobile-trigger {
      display: block;
    }
  }
}

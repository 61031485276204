.innovation {
  margin-top: 120px;

  &__image {
    background-image: url('../images/InnovatieICT.png');
    background-position: right center;
    background-size: cover;
    padding-top: 100%;
    width: 100%;
    height: auto;
    border-radius: 100%;
  }

  @include media-breakpoint-down(md) {
    margin-top: 70px;

    &__image {
      width: 100%;
      max-width: 350px;
      height: 350px !important;
      margin: auto;
      padding-top: 0;
      margin-bottom: 30px;
    }
  }
}
